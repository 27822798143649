import React from 'react';
import { graphql } from 'gatsby';
import Menu from '../components/menu';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

interface Props {
    data: {
        markdownRemark: {
            html: string;
            frontmatter: {
                slug: string;
                title: string;
                thumbnail;
            };
        };
    };
}

const Template: React.FunctionComponent<Props> = ({ data }: Props) => {
    const { markdownRemark } = data;
    const { frontmatter, html } = markdownRemark;
    const thumbnail = getImage(frontmatter.thumbnail);

    return (
        <div className="application">
            <Menu />
            <div className="page-container">
                <section className="project-section">
                    <h1>{frontmatter.title}</h1>
                    {/* <GatsbyImage image={thumbnail} alt="tumbnail" /> */}
                    <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: html }} />
                </section>
            </div>
        </div>
    );
};

export default Template;

export const pageQuery = graphql`
    query($slug: String!) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
                slug
                title
                thumbnail {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
        }
    }
`;
